<template>
  <b-form-group :label="label" label-for="password">
    <validation-provider #default="{ errors }" :name="name" :rules="rules">
      <b-input-group
        class="input-group-merge"
        :class="errors.length > 0 ? 'is-invalid' : null"
      >
        <b-form-input
          :value="value"
          @input="(e) => $emit('input', e)"
          :type="pass.type"
          :state="errors.length > 0 ? false : null"
          class="form-control-merge"
          placeholder="············"
        />
        <b-input-group-append is-text>
          <feather-icon
            :icon="pass.icon"
            class="cursor-pointer"
            @click="isTypePass = !isTypePass"
          />
        </b-input-group-append>
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { required, min } from "@validations";
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";

export default {
  data: () => ({
    isTypePass: true,
    required,
    min,
  }),
  props: {
    label: { type: String, default: "Password" },
    value: { type: String, required: true },
    name: { type: String, default: "Password" },
    rules: { type: String, default: "required|min:8" },
  },
  computed: {
    pass() {
      const pass = this.isTypePass;
      return {
        icon: pass ? "EyeIcon" : "EyeOffIcon",
        type: pass ? "password" : "text",
      };
    },
  },
  components: {
    ValidationProvider,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
};
</script>

<style>
</style>
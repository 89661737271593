<template>
  <validation-observer ref="registerForm">
    <b-form class="auth-register-form mt-2" @submit.prevent="update">
      <b-card :title="`${!isLoginWithProvider ? 'Modify' : ''} user settings`">
        <div class="form-settings">
          <b-form-group label="Username">
            <b-form-input
              v-model="form.username"
              placeholder="Username"
              disabled
            />
          </b-form-group>
          <b-form-group label="Email">
            <b-form-input v-model="form.email" placeholder="Email" disabled />
          </b-form-group>
          <div v-if="!isLoginWithProvider">
            <Password
              v-model="form.oldPassword"
              name="Old Password"
              label="Old password"
              rules="required"
            />
            <Password
              v-model="form.newPassword"
              label="New password"
              rules="required|min:8"
            />
            <Password
              v-model="form.confirmPassword"
              name="Confirm Password"
              label="Confirm password"
              rules="required|confirmed:Password"
            />
          </div>
          <div class="passForget" v-else>
            You can set password by using
            <b-button
              @click="forgetPassword"
              class="forgetBtn"
              variant="link"
              :disabled="loadingForgetPassword"
            >
              <b-spinner v-if="loadingForgetPassword" small />
              Forget Password
            </b-button>
          </div>
        </div>
        <b-button v-if="!isLoginWithProvider" class="mt-2" @click="update">
          <b-spinner v-if="loading" small></b-spinner>
          Update
        </b-button>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min } from "@validations";
import { BCard, BFormInput, BForm, BButton, BFormGroup } from "bootstrap-vue";
import Password from "@/components/util/Password.vue";
import { mapGetters, mapState } from "vuex";
import UserService from "@/services/user.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Auth from "@/services/auth.service.js";

export default {
  data: () => ({
    loading: false,
    loadingForgetPassword: false,
    form: {
      username: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    // validation rules
    required,
    min,
  }),
  computed: {
    ...mapGetters(["isLoginWithProvider"]),
    ...mapState(["user"]),
    userObj() {
      return this.user.user;
    },
  },
  mounted() {
    this.form.username = this.userObj.username;
    this.form.email = this.userObj.email;
  },
  methods: {
    async forgetPassword() {
      this.loadingForgetPassword = true;
      try {
        const { data } = await Auth.sendResetPass(this.userObj.email);
        this.$swal({
          icon: "success",
          title: "Good",
          text: data,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Faild to send a reset link..",
          text: e?.response?.data || "something went wrong",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
      this.loadingForgetPassword = false;
    },
    async update() {
      this.loading = true;
      const success = await this.$refs.registerForm.validate();
      if (success) {
        try {
          await UserService.updatePass({
            old_password: this.form.oldPassword,
            new_password: this.form.newPassword,
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Updated",
              icon: "CheckIcon",
              text: "Password has been updated successfully",
              variant: "success",
            },
          });
        } catch (e) {
          const msg =
            e.response.status == 422
              ? Object.values(e.response.data.errors)[0][0]
              : e.response.status == 403
              ? e.response.data
              : "Something went wrong, please try again";
          console.log(msg);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Faild to update",
              icon: "XIcon",
              text: msg,
              variant: "danger",
            },
          });
        }
        console.log("Updated ..");
      }
      this.loading = false;
    },
  },
  components: {
    BForm,
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    Password,
    ToastificationContent,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
};
</script>

<style lang="scss" scoped>
.form-settings {
  > * {
    margin-bottom: 15px;
  }
  .forgetBtn,
  .passForget {
    font-weight: bold;
    font-size: 16px;
  }
  .passForget {
    background: #171d314d;
    border-radius: 22px;
    padding: 15px;
  }
}
</style>
import api from "@axios";

class User {
     // All users (for admin)
     static async all(queries) {
        const {data} = await api.get(`/users${queries}`)
        return data;
    }
    // Update user (for admin)
    static async update({ id, ...rest }) {
        await api.put(`/users/${id}`, rest)
    }
    // Add user (for admin)
    static async add(data) {
        await api.post(`/users`, data)
    }
    // Get specific user (for admin)
    static async specific(id) {
        const { data } = await api.get(`/users/${id}`)
        return data
    }
    // Update password
    static async updatePass(formData) {
        await api.post('/users/change-pass', formData)
    }
}

export default User;